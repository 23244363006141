@font-face {
  font-family: 'icomoon';
  src: url('./icons/icomoon.eot?64xt3c');
  src: url('./icons/icomoon.eot?#iefix64xt3c') format('embedded-opentype'),
    url('./icons/icomoon.woff?64xt3c') format('woff'),
    url('./icons/icomoon.ttf?64xt3c') format('truetype'),
    url('./icons/icomoon.svg?64xt3c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./proximanova/proximanova-regular-webfont.eot');
  src: url('./proximanova/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./proximanova/proximanova-regular-webfont.woff2') format('woff2'),
    url('./proximanova/proximanova-regular-webfont.woff') format('woff'),
    url('./proximanova/proximanova-regular-webfont.ttf') format('truetype'),
    url('./proximanova/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('./proximanova/proximanova-bold-webfont.eot');
  src: url('./proximanova/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./proximanova/proximanova-bold-webfont.woff2') format('woff2'),
    url('./proximanova/proximanova-bold-webfont.woff') format('woff'),
    url('./proximanova/proximanova-bold-webfont.ttf') format('truetype'),
    url('./proximanova/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('./proximanova/proximanova-light-webfont.eot');
  src: url('./proximanova/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./proximanova/proximanova-light-webfont.woff2') format('woff2'),
    url('./proximanova/proximanova-light-webfont.woff') format('woff'),
    url('./proximanova/proximanova-light-webfont.ttf') format('truetype'),
    url('./proximanova/proximanova-light-webfont.svg#proxima_novalight') format('svg');
  font-weight: normal;
  font-style: normal;
}
